import React, { useState ,useEffect} from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  makeStyles,
  Grid,
  Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { styled } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, Link, useLocation } from "react-router-dom";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  menuItems: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "40px",
    //marginRight: "24px",
    alignItems: "center",
    position: "relative", // To position the dropdown menu
  },
  drawerList: {
    width: 250,
    // backgroundColor: "#8EC5FC",
    // backgroundImage: "linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)",
    height: "100%",
  },
  buttonBox: {
    display: "flex",
    gap: 15,
    paddingBottom: "10px",
  },
  drawerListItem: {
    padding: "16px 24px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  drawerListItemText: {
    fontSize: "16px",
    color: theme.palette.text.primary,
  },
  drawerHeader: {
    padding: "16px 24px",
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  // dropdownDocs: {
  //   position: "absolute",
  //   top: "105%",
  //   //left: "30.5%",
  //   // width: "500px",

  //   padding: "4% 8%",
  //   background: "#181721",
  //   boxShadow: theme.shadows[5],

  //   zIndex: "15 !important",
  //   display: "none",
  //   "& a": {
  //     padding: theme.spacing(2),
  //     display: "block",
  //     textDecoration: "none",
  //     color: "#E5E5E5",
  //   },
  //   "& .docsItem": {
  //     border: "1px solid #515151",
  //     padding: "15px",
  //     borderRadius: "10px",
  //     background: "#28272F",
  //     display: "flex",
  //     flexDirection: "column",
  //     gap: "10px",
  //   },
  //   "& h2": {
  //     fontWeight: 700,
  //     fontSize: "24px",
  //     lineHeight: "30px",
  //     marginTop: "6px",
  //   },
  //   "& .rightList": {
  //     backgroundColor: "#181721",
  //     paddingTop: "3%",
  //     paddingBottom: "5%",
  //     paddingLeft: "3%",
  //     paddingRight: "12%",
  //   },
  //   "& .leftList": {
  //     backgroundColor: "#14131D",
  //     paddingTop: "3%",
  //     paddingBottom: "5%",
  //     paddingLeft: "10%",
  //     paddingRight: "9%",
  //   },
  // },
  dropdownCompany: {
    position: "absolute",
    top: "105%",
    //left: "30.5%",
    // width: "500px",

    padding: "3% 4%",
    background: "#181721",
    boxShadow: theme.shadows[5],

    zIndex: "15 !important",
    display: "none",
    "& a": {
      padding: theme.spacing(2),
      display: "block",
      textDecoration: "none",
      color: "#E5E5E5",
    },

    "& .companyItems": {
      textDecoration: "none",
      paddingLeft: "0px",
      paddingBottom: "0px",
    },
    "& h2": {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      marginTop: "6px",
    },
    "& .rightList": {
      backgroundColor: "#181721",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "3%",
      paddingRight: "12%",
    },
    "& .leftList": {
      backgroundColor: "#14131D",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "10%",
      paddingRight: "9%",
    },
  },
  dropdownMenu: {
    position: "absolute",
    top: "105%", // Position the dropdown below the menu item
    //left: "-320px",
    right: "-17%",
    width: "102vw", // Full width of the viewport
    zIndex: "15 !important",
    boxShadow: theme.shadows[5],
    //overflowX:"hidden",
    //zIndex: 1,
    display: "none", // Hidden by default
    "& .companyItems": {
      textDecoration: "none",
      paddingLeft: "0px",
      paddingBottom: "0px",
    },
    "& a": {
      padding: theme.spacing(2),
      display: "block",
      textDecoration: "none",
      color: "#E5E5E5",
    },
    "& h2": {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      marginTop: "6px",
      color: "#ffff",
    },
    "& body2": {
      color: "#ffff",
    },
    "& .rightList": {
      backgroundColor: "#181721",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "3%",
      //paddingRight: "12%",
    },
    "& .leftList": {
      backgroundColor: "#14131D",
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "10%",
      paddingRight: "9%",
    },
    "@media (min-width:1550px)": {
      right: "-16%",
    },
    "@media (max-width:1280px)": {
      right: "-3%",
    },
    // "@media (min-width:1500px)": {
    //   width:"102vw"
    // },
    "& .docsItem": {
      border: "1px solid #515151",
      padding: "15px",
      borderRadius: "10px",
      background: "#28272F",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  },
  dropdownMenuVisible: {
    display: "block", // Show the dropdown when hovering
  },
}));

const NavBar = ({ bgColor }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDocsHovered, setIsDocsHovered] = useState(false);
  const [isProductsHovered, setIsProductsHovered] = useState(false);
  const [isCompanyHovered, setIsCompanyHovered] = useState(false);
  const isMobile = useMediaQuery("(max-width:960px)");
  const history = useHistory();
  const location = useLocation();
  const [anchorNoti, setAnchorNoti] = useState(null);
  const openNoti = Boolean(anchorNoti);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 50) { // Set the scroll threshold
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNotiClose = () => {
    setAnchorNoti(null);
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignUp = () => {
    history.push("/signup");
  };

  const handleLogIn = () => {
    history.push("/login");
  };
  const isActive = (path) => {
    const firstSegment = location.pathname.split("/")[1];
    return firstSegment === path;
  };
  const NavBarContainer = styled(AppBar)(({ isScrolled }) => ({
    background: "#FDF6E6",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingLeft: "10%",
    paddingRight: "10%",
    height: "106px",
    position: 'fixed', // Always fixed now
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1300,
    boxShadow: isScrolled ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none', // Add shadow only when scrolled
    transition: 'box-shadow 0.3s ease-in-out', // Smooth box-shadow transition
    "@media (max-width:1280px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    "& .MuiToolbar-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
      marginTop: "23px",
    },
  }));

  const Logo = styled("img")({
    //height: "40px",
    marginRight: "20px",
  });
  const handleNotiClick = (event) => {
    setAnchorNoti(event.currentTarget);
  };
  return (
    <div>
      <NavBarContainer position="static">
        <Toolbar>
          {isMobile && (
            <Box>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                style={{ paddingBottom: "10px" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          <Logo
            src="/images/orangeLogo.svg"
            alt="Logo"
            onClick={() => history.push("/")}
            style={{
              paddingBottom: "10px",
              marginLeft: "-12px",
              cursor: "pointer",
            }}
          />
          {!isMobile && (
            <Box className={classes.menuItems} flexGrow={1}>
              {["Products", "Features", "Docs", "Company", "Pricing"].map(
                (text) => {
                  let path = "";

                  if (text == "Features") {
                    path = "/features";
                  }
                  if (text == "Pricing") {
                    path = "/pricing";
                  }

                  return (
                    <Box
                      key={text}
                      onMouseLeave={() => {
                        if (text === "Products") setIsProductsHovered(false);
                        if (text === "Docs") setIsDocsHovered(false);
                        if (text === "Company") {
                          setIsCompanyHovered(false);
                        }
                      }}
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: "10px",
                        color: "#403B52",
                      }}
                      // position="relative"
                    >
                      <Link
                        to={path !== "" && path}
                        onMouseEnter={() => {
                          if (text === "Products") {
                            setIsCompanyHovered(false);
                            setIsDocsHovered(false);
                            setIsProductsHovered(true);
                          }
                          if (text === "Docs") {
                            setIsCompanyHovered(false);
                            setIsProductsHovered(false);
                            setIsDocsHovered(true);
                          }
                          if (text === "Company") {
                            setIsProductsHovered(false);
                            setIsDocsHovered(false);
                            setIsCompanyHovered(true);
                          }
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          variant="h6"
                          display="inline"
                          color="#403B52"
                          style={{
                            // margin: "0 10px",
                            cursor: "pointer",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "18.78px",
                            color: isActive(text.toLowerCase())
                              ? "#F36D36"
                              : "#403B52",
                          }}
                          onMouseEnter={(e) =>
                            (e.target.style.color = "#F36D36")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.color = "#403B52")
                          }
                        >
                          {text}
                        </Typography>
                      </Link>
                      {text === "Products" && (
                        <Box
                          className={`${classes.dropdownMenu} ${
                            isProductsHovered ? classes.dropdownMenuVisible : ""
                          }`}
                          // onMouseLeave={() =>
                          //   text === "Products" && setIsProductsHovered(false)
                          // }
                        >
                          <Grid container>
                            <Grid item className="leftList">
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <Grid container direction="row">
                                    <Grid item>
                                      <img src="/images/message.svg" />
                                    </Grid>
                                    <Grid item style={{ marginLeft: "10px" }}>
                                      <Typography variant="h2">
                                        Chat & Messaging
                                      </Typography>
                                      <Typography style={{ color: "#ffff" }}>
                                        Flexible, Scalable, and feature-rich and{" "}
                                        <br />
                                        SDKs for your in-app messaging
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Grid container>
                                    <Grid item>
                                      <Grid container direction="row">
                                        <Grid item>
                                          <img src="/images/featuresCall.svg" />
                                        </Grid>
                                        <Grid
                                          item
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <Typography variant="h2">
                                            Voice & Video Calls
                                          </Typography>
                                          <Typography
                                            style={{ color: "#ffff" }}
                                          >
                                            Integrate real-time video and audio{" "}
                                            <br /> engagement to your product
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="rightList"
                              style={{ flex: 1 }}
                            >
                              <Typography
                                variant="h2"
                                style={{ color: "#E5E5E580" }}
                              >
                                Features
                              </Typography>
                              <Grid
                                container
                                style={{ marginTop: "20px" }}
                                spacing={2}
                              >
                                <Grid item sm={6}>
                                  <Grid container>
                                    <Grid item>
                                      <img src="/images/list.svg" />
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{ height: "100%" }}
                                      >
                                        <Typography style={{ color: "#ffff" }}>
                                          One-on-One Text Chat
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6}>
                                  <Grid container>
                                    <Grid item>
                                      <img src="/images/list.svg" />
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{ height: "100%" }}
                                      >
                                        <Typography style={{ color: "#ffff" }}>
                                          Media Sharing
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6}>
                                  <Grid container>
                                    <Grid item>
                                      <img src="/images/list.svg" />
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{ height: "100%" }}
                                      >
                                        <Typography style={{ color: "#ffff" }}>
                                          Video and Audio Calls
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6}>
                                  <Grid container>
                                    <Grid item>
                                      <img src="/images/list.svg" />
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{ height: "100%" }}
                                      >
                                        <Typography style={{ color: "#ffff" }}>
                                          Typing Indicators
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6}>
                                  <Grid container>
                                    <Grid item>
                                      <img src="/images/list.svg" />
                                    </Grid>
                                    <Grid item>
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{ height: "100%" }}
                                      >
                                        <Typography style={{ color: "#ffff" }}>
                                          Group Chats
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {text === "Docs" && (
                        <Box
                          className={`${classes.dropdownMenu} ${
                            isDocsHovered ? classes.dropdownMenuVisible : ""
                          }`}
                          // onMouseLeave={() =>
                          //   text === "Products" && setIsProductsHovered(false)
                          // }
                        >
                          <Grid container>
                            <Grid item className="leftList">
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: "10px",
                                        width: "400px",
                                      }}
                                    >
                                      <Typography variant="h2">
                                        Supported Technology
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "#ffff",
                                          marginTop: "20px",
                                        }}
                                      >
                                        Lorem ipsum dolor sit amet consectetur.
                                        Neque gravida amet at sit sollicitudin
                                        tristique vel pretium sollicitudin. Sed
                                        sem placerat odio eu ut augue. Egestas
                                        viverra nec pellentesque
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="rightList"
                              style={{ flex: 1 }}
                            >
                              <Grid
                                container
                                direction="column"
                                spacing={3}
                                style={{ width: "306px" }}
                              >
                                <Grid item>
                                  <Typography
                                    variant="h2"
                                    style={{
                                      color: "#E5E5E580",
                                      width: "306px",
                                    }}
                                  >
                                    Supported Technologies
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  justifyContent="space-between"
                                  //spacing={4}
                                >
                                  <Grid item>
                                    <Box className="docsItem">
                                      <Typography
                                        style={{
                                          color: "#FFFFFFCC",
                                          textAlign: "center",
                                        }}
                                      >
                                        SWIFT
                                      </Typography>
                                      <img src="/images/swift.svg" />
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    {" "}
                                    <Box className="docsItem">
                                      <Typography
                                        style={{
                                          color: "#FFFFFFCC",
                                          textAlign: "center",
                                        }}
                                      >
                                        Kotlin
                                      </Typography>
                                      <img src="/images/kotlin.svg" />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      {text === "Company" && (
                        // <Box
                        //   className={`${classes.dropdownCompany} ${
                        //     isCompanyHovered ? classes.dropdownMenuVisible : ""
                        //   }`}
                        //   // onMouseLeave={() =>
                        //   //   text === "Docs" && setIsDocsHovered(false)
                        //   // }
                        //   position="relative"
                        // >

                        // </Box>
                        <Box
                          className={`${classes.dropdownMenu} ${
                            isCompanyHovered ? classes.dropdownMenuVisible : ""
                          }`}
                          // onMouseLeave={() =>
                          //   text === "Products" && setIsProductsHovered(false)
                          // }
                        >
                          <Grid container>
                            <Grid item className="leftList">
                              <Grid container direction="column" spacing={5}>
                                <Grid item>
                                  <Grid container direction="row">
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: "10px",
                                        width: "400px",
                                      }}
                                    >
                                      <Typography variant="h2">
                                        Company
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "#ffff",
                                          marginTop: "20px",
                                          
                                        }}
                                      >
                                        Lorem ipsum dolor sit amet consectetur.
                                        Neque gravida amet at sit sollicitudin
                                        tristique vel pretium sollicitudin. Sed
                                        sem placerat odio eu ut augue. Egestas
                                        viverra nec pellentesque
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              className="rightList"
                              style={{ flex: 1 }}
                            >
                              <Grid
                                container
                                direction="column"
                                style={{ marginLeft: "20%" }}
                              >
                                <Typography
                                  variant="h2"
                                  style={{ color: "#E5E5E580" }}
                                >
                                  Company
                                </Typography>

                                <Box>
                                  <Link
                                    to={"/aboutus"}
                                    style={{
                                      textDecoration: "none",
                                      paddingLeft: "0px",
                                      //margin: "5px ,0px",
                                      paddingBottom: "0px",
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      About Us
                                    </Typography>
                                  </Link>
                                  <Link
                                    to={"/whymzdk"}
                                    className="companyItems"
                                  >
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Why Mzadchat SDK
                                    </Typography>
                                  </Link>
                                  <Link to={"/t&c"} className="companyItems">
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Terms & Conditions
                                    </Typography>
                                  </Link>
                                  <Link to={"/policy"} className="companyItems">
                                    <Typography
                                      variant="h6"
                                      display="inline"
                                      color="#000000CC"
                                      style={{
                                        color: "#FFFFFFCC",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Privacy Policy
                                    </Typography>
                                  </Link>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  );
                }
              )}
              <Box className={classes.buttonBox}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    border: "none",
                    borderRadius: "15px",
                    width: "135px",
                  }}
                  onClick={handleSignUp}
                >
                  Sign Up
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    border: "none",
                    borderRadius: "15px",
                    width: "135px",
                  }}
                  onClick={handleLogIn}
                >
                  Log In
                </Button>
              </Box>
            </Box>
          )}
        </Toolbar>
      </NavBarContainer>
      <Drawer
        anchor="left"
        open={isMobile ? drawerOpen : false}
        onClose={handleDrawerToggle}
      >
        <Box
          className={classes.drawerList}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <List>
            {["Features", "Pricing"].map((text) => (
              <ListItem button className={classes.drawerListItem} key={text}>
                <ListItemText
                  primary={text}
                  classes={{ primary: classes.drawerListItemText }}
                />
              </ListItem>
            ))}
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={handleSignUp}
            >
              <ListItemText
                primary="Sign Up"
                classes={{ primary: classes.drawerListItemText }}
              />
            </ListItem>
            <ListItem
              button
              className={classes.drawerListItem}
              onClick={handleLogIn}
            >
              <ListItemText
                primary="Log In"
                classes={{ primary: classes.drawerListItemText }}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default NavBar;
