import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";
import Header from "./Header";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    "& body2": {
      fontWeight: 400,
      fontSize: "16px !important",
      color: "#344054",
      textAlign: "center !important",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      maxWidth: "413px !important",
      width: "100%",
    },
    "& .MuiDialogContent-root": {
      padding: "10px 0px",
    },
    "& h2": {
      width: "fit-content",
    },
    "& h4": {
      fontWeight: 600,
      fontSize: "28px !important",
      color: "#0C111D",
      lineHeight: "36px",
    },
    "& h5": {
      fontWeight: 400,
      fontSize: "16px !important",
      color: "#0C111D !important",
      textAlign: "center",
      lineHeight: "22px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px !important",
      },
    },

    "& hr": {
      margin: "10px 0",
    },
    [theme.breakpoints.down("xs")]: {
      "& button": {
        width: "90px !important",
      },
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    background: "#F2F4F7",
    overflow: "hidden",
  },
  headerSection: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "1%",
  },
  // sectionTitle: {
  //     ...typographyStyles.h4,
  // },
  contentSection: {
    display: "flex",
    height: "100%",
  },
  sectionNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  siderbarStyle: {
    display: "flex",
    //alignItems: "center",
    //justifyContent: "center",
    minWidth: "250px",
    //maxWidth: "250px",
    background: "#FFFFFF",
    //width: "15%",
    overflowY: "auto", // Enables vertical scrolling
    maxHeight: "calc(100vh - 110px)",
    // paddingTop:"20px",
    paddingBottom: "20px",
    //padding: "20px",
    scrollbarWidth: "none", // Hides scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hides scrollbar for Webkit browsers
    },
    marginTop: "10px",

    boxShadow: "0 -10px 10px -10px #D9D9D9, 10px 0 10px -10px #D9D9D9",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentStyle: {
    // width: '80%',
    padding: "0 30px 0 10px",
    //paddingRight:"10px",
    //marginLeft: '10px',
    marginTop: "10px",
    // minHeight: '100vh',
    overflowY: "auto", // Enables vertical scrolling
    maxHeight: "calc(100vh - 90px)",
    //height: '100%',
    scrollbarWidth: "none", // Hides scrollbar for Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Hides scrollbar for Webkit browsers
    },
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "10px",
    },
  },

  footerSection: {
    left: "0",
    right: "0",
    bottom: "0",
  },
}));

export default function MainLayout({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  //const navigate = useNavigate();

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
    console.log("logout");
  };

  const handleCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.headerSection}>
        <Header />
      </Box>
      <Box className={classes.contentSection}>
        <Box className={classes.siderbarStyle}>
          <Sidebar onLogoutClick={handleLogoutClick} />
          {isLogoutModalOpen && (
            <Dialog
              open={isLogoutModalOpen}
              onClose={() => handleCloseLogoutModal()}
              className={classes.dialog}
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogContent>
                <Box className={classes.mainBox}>
                  <img src="/images/logOutPopup.svg" alt="gps" />
                  <Typography variant="h4" style={{ marginTop: "20px" }}>
                    Log Out
                  </Typography>
                  <Typography variant="h5" style={{ marginTop: "20px" }}>
                    Are you sure, you want to log out ?
                  </Typography>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        style={{
                          marginTop: "25px",
                          background: "#E1E1E1",
                          color: "#0C111D",
                          width: "150px",
                        }}
                        onClick={() => setIsLogoutModalOpen(false)}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        style={{ marginTop: "25px", width: "150px" }}
                        onClick={() => {
                          // setIsLogoutModalOpen(false);
                          history.push("/");
                        }}
                      >
                        Yes
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
            </Dialog>
          )}
        </Box>
        <Box className={classes.contentStyle}>{children}</Box>
      </Box>
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node,
};
