import React from 'react'
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
    Button,
    Grid,
    makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types"

import NavBar from './Dashboard Layout/Navbar';
import Footer from './Footer/footer';

export default function Homelayout({children}) {
    return (
        <Grid>
            <Grid>
                <NavBar />
            </Grid>
            <Grid>
                {children}
            </Grid>

            <Grid>
                <Footer />
            </Grid>

        </Grid>
    )

}
Homelayout.propTypes = {
    children: PropTypes.node,
};