import React from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  Container,
  IconButton,
  Divider,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineFacebook } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineLinkedin } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { useHistory, } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  // mainbox: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  //     [theme.breakpoints.down("md")]: {
  //         flexWrap: "wrap"
  //     }
  // },

  footer: {
    backgroundColor: "#FDF6E6",
    color: "#fff",
    padding: "32px 0px",
  },
  lowerbox: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  lowerbox1: {
    display: "flex",
    gap: "22px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  lowerbox2: {
    display: "flex",
    gap: "22px",
    //marginTop: "-2%",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  column: {
    // padding: theme.spacing(1),
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  link: {
    display: "block",
    marginBottom: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.8)",
    textDecoration: "none",
    fontSize: "14px",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  center: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  logo: {
    height: 40,
    marginBottom: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {/* <Divider style={{marginBottom:"15px", marginTop:"25px"}}/> */}
      <Box className={classes.footer}>
        <Container
          style={{ maxWidth: "100%", paddingLeft: "10%", paddingRight: "10%" }}
        >
          <Grid container justifyContent="space-between" className={classes.mainbox}>
            <Grid item style={{ width: "148px" }} className={classes.column}>
              <Typography variant="h6" className={classes.heading}>
                Products
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <img src="/images/ios.svg" />
                </Grid>
                <Grid item>
                  <Link href="login" className={classes.link}>
                    iOS Chat SDK
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item>
                  <img src="/images/and.svg" />
                </Grid>
                <Grid item>
                  <Link href="login" className={classes.link}>
                    Android Chat SDK
                  </Link>
                </Grid>
              </Grid>

              <Link href="#" className={classes.link}></Link>
              <Link href="#" className={classes.link}></Link>
              <Link href="#" className={classes.link}></Link>
            </Grid>

            <Grid item style={{ width: "148px" }} className={classes.column}>
              <Typography variant="h6" className={classes.heading}>
                Features
              </Typography>
              <Link href="/features" className={classes.link}>
                One-on-One Text Chat
              </Link>
              <Link href="/features" className={classes.link}>
                Media Sharing
              </Link>
              <Link href="/features" className={classes.link}>
                Video and Audio Calls
              </Link>
              <Link href="/features" className={classes.link}>
                Typing Indicators
              </Link>
              <Link href="/features" className={classes.link}>
                Group Chats
              </Link>
            </Grid>

            <Grid item style={{ width: "148px" }} className={classes.column}>
              <Typography variant="h6" className={classes.heading}>
                Docs
              </Typography>
              <Link href="/signup" className={classes.link}>
                Chat Messaging Docs
              </Link>
              <Link href="/signup" className={classes.link}>
                Video & Audio Docs
              </Link>
              <Link href="/signup" className={classes.link}>
                Quickstarts
              </Link>
              <Link href="/signup" className={classes.link}>
                Tutorials
              </Link>
            </Grid>

            <Grid item style={{ width: "129px" }} className={classes.column}>
              <Typography variant="h6" className={classes.heading}>
                Company
              </Typography>
              <Link href="/aboutus" className={classes.link}>
                About us
              </Link>
              <Link href="/whymzdk" className={classes.link}>
                Why Mzadchat SDK
              </Link>
              <Link href="/t&c" className={classes.link}>
                Terms & Conditions
              </Link>
              <Link href="/policy" className={classes.link}>
                Privacy Policy
              </Link>
              <Link href="/security" className={classes.link}>
                Security
              </Link>
            </Grid>

            <Grid item style={{ width: "85px" }} className={classes.column}>
              <Typography variant="h6" className={classes.heading}>
                Contact Us
              </Typography>
              <Link href="/contactSales" className={classes.link}>
                Sales
              </Link>
              <Link href="/helpCenter" className={classes.link}>
                Support
              </Link>
              <Link href="/bugReport" className={classes.link}>
                Bug Report
              </Link>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: "15px", marginBottom: "15px" }}></Divider>
          <Box className={classes.lowerbox}>

            <Grid container justifyContent="space-between" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Grid item>
                <img src="/images/orangeLogo.svg" alt="img" />
              </Grid>
              <Grid item>
                <Box
                  className={classes.center}
                >
                  <Typography variant="h6">
                    MZADSDK All Rights Reserved
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  className={classes.center}
                >
                  <Box>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item>
                        <Box
                          className={classes.center}
                        >
                          <Link
                            href="/t&c"
                            className={classes.link}
                            style={{ marginBottom: "0px" }}
                          >
                            {" "}
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          className={classes.center}
                        >
                          <Link
                            href="/policy"
                            className={classes.link}
                            style={{ marginBottom: "0px" }}
                          >
                            {" "}
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          className={classes.center}
                        >
                          <Link
                            href="/security"
                            className={classes.link}
                            style={{ marginBottom: "0px" }}
                          >
                            {" "}
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box
                          className={classes.center}
                        >
                          <Box>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Box
                                  className={classes.center}
                                >
                                  <img
                                    src="/images/footerX.svg"
                                    onClick={() => window.location.href = "https://twitter.com"}
                                    style={{ cursor: "pointer" }}
                                    alt="Twitter"
                                  />

                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  className={classes.center}
                                >
                                  <img
                                    src="/images/footerFb.svg"
                                    onClick={() => window.location.href = "https://facebook.com/"}
                                    style={{ cursor: "pointer" }}
                                    alt="Facebook"
                                  />

                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  className={classes.center}
                                >
                                  <img
                                    src="/images/footerLn.svg"
                                    onClick={() => window.location.href = "https://linkedin.com/"}
                                    style={{ cursor: "pointer" }}
                                    alt="LinkedIn"
                                  />

                                </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  className={classes.center}
                                >
                                  <img
                                    src="/images/footerEmail.svg"
                                    onClick={() => window.location.href = "https://mail.google.com/"}
                                    style={{ cursor: "pointer" }}
                                    alt="Gmail"
                                  />

                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
