import _ from "lodash";
import { colors, createTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: "transparent",
        fontFamily:"Inter",
        // width:'33.5%'
        //background: "#F2F4F7",
        height: "48px",
        color:"#403B52 !important",
        textWrap: "nowrap",
        padding: "10px",
        fontWeight:400 
      },
      head: {
        height: "30px",
        fontWeight:600 
      },
    },
    MuiTableRow: {
      root: {},
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },
    MuiTablePagination: {
      caption: {
        display: "none",
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "black",
        },
        outlined: {
          color: "black",
        },
        page: {
          "&.Mui-selected": {
            background: "#681E65",
            color: "white",
          },
        },
      },
    },
    MuiSelect: {
      outlined: {
        color: "balck",
      },
      select: {
        //fontFamily:"Inter",
        fontSize: "16px",
      },
      selectMenu:{
        minHeight:"auto"
      }
    },
    MuiOutlinedInput: {
      root: {
        // background: "#F7F7F7",
        borderRadius: "15px",
        height: "48px",
      },
      multiline: {
        height: "auto",
      },
      notchedOutline: {
        borderColor: "rgba(0, 0, 0, 0.08)",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#701748",
          borderColor: "#701748",
        },
        "&.Mui-focused": {
          color: "#701748",
        },
      },
      input: {
        "&::placeholder": {
          color: "#98A2B3", 
          opacity: 1, 
        },
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        background: "#fff",
        borderRadius: "10px",
        padding: "26px 20px",
        boxShadow: "none",
      },
      elevation2: {
        // background:
        //   "linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 68.15%)",
        border: "none",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "none",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiMenuItem: { root: { paddingLeft: "20px" } },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
        color: "#E9E9E9"
        
      },
      colorPrimary: {
        "&.Mui-checked": { color: "#F36D36" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
        fontSize: "16px",
        marginLeft: "0px",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
        borderRadius: "20px !important",
        padding: "20px",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiAutocomplete: {
      option: {
        fontFamily: "Raleway !important",
        fontSize: "12px !important",
        fontWeight: "400 !important",
        lineHeight: "18px !important",
        letterSpacing: "0px !important",
        textAlign: "left !important",
      },
    },
    MuiSwitch: {
      root: {
        // width:"44px",
        // height:"24px"
      },
      colorPrimary: {
        color: "red",
      },
      track: {
        color: "red",
        background: "green",
      },
      switchBase: {
        //color:"green",
        "&$checked": {
          color: "red",
        },
      },
      checked: {
        switchBase: {
          color: "green",
        },
      },
    },
    MuiButton: {
      containedSecondary: {
        color: "black",
        height: "50px",
        padding: "15px 40px",
        fontSize: "18px",
        border: "2px solid #681E65",
        background: "#fff",
        fontWeight: "600",
        lineHeight: "21px",
        fontFamily: "'Raleway'",
        borderRadius: "100px",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          border: "2px solid #F36D36",
          boxShadow: "none !important",
          color: "#fff",
          backgroundColor: "#F36D36",
        },
        "@media (max-width: 600px)": {
          fontSize: "14px",  // Decrease font size for small screens
          padding: "10px 30px",  // Adjust padding as needed
        },
      },
  
      containedPrimary: {
        color: "#fff",
        height: "52px",
        padding: "14px 12px",
        fontSize: "16px",
        backgroundColor: "#F36D36",
        fontWeight: "500",
        lineHeight: "20px",
        fontFamily: "Raleway",
        borderRadius: "15px",
        border: "0px solid white",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#F36D36",
        },
        "@media (max-width: 600px)": {
          fontSize: "14px",  // Decrease font size for small screens
          padding: "12px 10px",  // Adjust padding as needed
        },
      },
  
      contained: {
        color: "#fff",
        height: "40px",
        padding: "10px 39px",
        fontSize: "14px",
        background: "#fff",
        border: "1px solid #4D164F",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: "Raleway",
        borderRadius: "50px",
        backgroundColor: "#F36D36",
        "@media (max-width: 600px)": {
          fontSize: "12px",  // Decrease font size for small screens
          padding: "8px 30px",  // Adjust padding as needed
        },
      },
  
      outlinedPrimary: {
        color: "#080515",
        border: "1px solid #4D164F",
        padding: "5px 25px",
        fontWeight: "500",
        borderRadius: "50px",
        fontSize: "13px",
        "@media (max-width: 600px)": {
          fontSize: "11px",  // Decrease font size for small screens
          padding: "4px 20px",  // Adjust padding as needed
        },
      },
  
      containedDisabled: {
        color: "#fff !important",
        height: "50px",
        padding: "15px 40px",
        fontSize: "14px",
        background: "linear-gradient(275deg, #4D164F 4.07%, #681E65 98.21%)",
        border: "2px solid #4D164F",
        fontWeight: "500",
        lineHeight: "21px",
        fontFamily: "Outfit",
        borderRadius: "50px",
        "@media (max-width: 600px)": {
          fontSize: "12px",  // Decrease font size for small screens
          padding: "10px 30px",  // Adjust padding as needed
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
      list: {
        padding: "10px",
      },
    },
    PrivateTabIndicator:{
      root:{
             top:0,
             //backgroundColor:"red"
      },
      colorPrimary:{
        backgroundColor:"#F36D36"
      }
    },
    MuiTab:{
      textColorPrimary:{
        "&.Mui-selected":{
          color:"#F36D36"
        }
       // color:"#F36D36"
       
      },
      wrapper:{
       // color:"#F36D36"
      },
      root:{
        '@media (max-width: 2200px)': {
      minWidth: 0,  // Set min-width to 0 for screens <= 600px
    },
      },

    },
    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Raleway",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#fff",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#F36D36",
      dark: "#080515",
      light: "rgba(0, 0, 0, 0.60)",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#DC0404",
      dark: "#DC0404",
      light: "#DC0404",
    },
    text: {
      primary: "#161E29",
      secondary: "#344054",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createMuiTheme = (config = {}) => {
  let theme = createTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
