import React, { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "../context/Auth";

export default function AuthGuard({ children }) {
  const location = useLocation();
  const auth = useContext(AuthContext);

  const nonGuardedPaths = ["/login", "/"];
  const guardedPaths = [
    
  ];

  if (!auth.userLoggedIn && guardedPaths.includes(location.pathname)) {
    return <Redirect to="/" />;
  }

  // if (nonGuardedPaths.includes(location.pathname)) {
  //   if (localStorage.getItem("userRole") === "PAT") {
  //     return <Redirect to="/patient-dashboard" />;
  //   }
  //   if (localStorage.getItem("userRole") === "DOC") {
  //     return <Redirect to="/doctor-appointment" />;
  //   }
  // }

  return <>{children}</>;
}
